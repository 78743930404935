html {
  font-size: 16px;
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F0EC;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: PressStart2P;
  src: url('./assets/font/PressStart2P-Regular.ttf');
}

.App {
  font-family: PressStart2P;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .App {
    padding: 0 20px
  }
}

.typed-cursor{
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
  50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}
.typed-fade-out{
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}

@keyframes typing {
  from {
      width: 0;
  }
}

@keyframes cursor-blink {
  50% {
      border-color: transparent;
  }
}
.MuiGrid-item {
  padding-top: 0 !important;
}